<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button class="right" size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <!--搜索条件区域-->
    <div class="searchWrapper">
      <el-form
        :inline="true"
        label-position="right"
        ref="searchForm"
        :model="detailInfo"
        class="demo-form-inline"
      >
        <el-form-item :label="$t('searchModule.Tag_Name')">
          {{ detailInfo.labelName }}
        </el-form-item>
        <el-form-item label="人数（人）">
          {{ detailInfo.userNumber }}
        </el-form-item>
        <el-form-item label="更新形式">
          {{ detailInfo.updateForm }}
        </el-form-item>
        <el-form-item label="创建人">
          {{ detailInfo.creatorName }}
        </el-form-item>
        <el-form-item :label="$t('searchModule.Operation_date')">
          {{ detailInfo.createTime }}
        </el-form-item>
        <el-form-item label="标签描述">
          {{ detailInfo.labelDesc }}
        </el-form-item>
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper bgFFF paddingB10">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column label="身份认证" width="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.authState == 0" style="color: red">未认证</span>
            <span v-if="scope.row.authState == -1" style="color: red">未认证</span>
            <span v-if="scope.row.authState == 1">已认证</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="140">
          <template slot-scope="scope">
            <el-button type="text" @click="showDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setIndex } from "../../common/js/public.js";
import store from "../../store";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 15,
      total: 0,
      rules: [],
      formInline: {},
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "memberId",
          label: "用户ID",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
        },
        {
          prop: "nickName",
          label: "昵称",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
        },
        {
          prop: "sexValue",
          label: "性别",
        },
        {
          prop: "balance",
          label: "余额(元)",
          formatter: (row, column, cellValue, index) => {
            return cellValue / 100;
          },
        },
        {
          prop: "registerTime",
          label: "注册日期",
        },
        {
          prop: "carInfo",
          label: "绑定车牌",
        },
      ],
      labelId: this.$route.query.labelId,
      detailInfo: {},
    };
  },
  mounted() {
    this.getDetailInfo();
    this.getUsersByLabelId();
  },
  methods: {
    // 查询标签详情
    getDetailInfo() {
      const opt = {
        url: "/acb/2.0/labelManage/labelDetail",
        method: "get",
        data: {
          labelId: this.labelId,
        },
        success: (res) => {
          this.detailInfo = res.value;
        },
      };
      this.$request(opt);
    },
    // 根据标签ID查询所有用户
    getUsersByLabelId() {
      const opt = {
        url: "/acb/2.0/labelManage/usersByLabel",
        method: "get",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          labelId: this.labelId,
        },
        success: (res) => {
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = parseInt(res.value.total);
        },
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getUsersByLabelId();
    },
    showDetail(cmd, data) {
      if (!store.state.menu.button.memberInfo) {
        this.$alert("没有查看用户信息的权限！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      sessionStorage.subPageAuthorityMemeber = JSON.stringify(this.$route.meta.authority);
      this.$router.push({
        path: "/memberinformationdetail2",
        query: { data: JSON.stringify(cmd), memberId: cmd.memberId },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.t_c
  text-align center
.fade-enter-active, .fade-leave-active
  transition opacity 0.5s
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
  opacity 0
.infoWrapper
  margin 20px auto
  overflow hidden
.infoWrapper .fl
  float left
  width 600px
  .content
    width 250px
    float left
    line-height 60px
    padding-left 30px
.infoWrapper .fl:nth-of-type(1)
  width 170px
  .img
    background url('./car-pic.png') no-repeat 12px center
    margin-left 70px
    width 150px
    height 150px
.breadcrumb
  height 35px
.content

  overflow hidden
  .chartsWrapper
    height 360px
    margin-bottom 20px
    .wrapper
      background #FFFFFF
      border 1px solid #C0CCDA
      border-radius 4px
      .title
        font-size 14px
        color #475669
        padding-left 17px
        height 50px
        line-height 50px
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.mask
  background rgba(0, 0, 0, 0) no-repeat center / contain
  // background: rgba(49,53,65,0.60) no-repeat center/contain;
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
<style scoped>
.info {
  width: 565px;
}
.info li {
  overflow: hidden;
  border-left: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
}

.info li div {
  float: left;
  min-height: 30px;
  line-height: 30px;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

.title {
  line-height: 50px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
  padding-left: 10px;
  color: skyblue;
}

.item-name {
  width: 100px;
  text-align: right;
}

.item-img {
  width: 281px;
  text-align: center;
  vertical-align: middle;
}

.item-img img {
  width: 80%;
  vertical-align: middle;
}

.item-content {
  width: 180px;
  text-align: center;
}

.balance {
  display: inline-block;
  padding: 2px 15px;
  line-height: 18px;
}

.item-img-wrapper {
  min-height: 124px !important;
  line-height: 124px !important;
  /* // border-bottom: 1px solid #ebeef5; */
}
.showEmp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  width: 65px;
}
.item-img-wrapper .item-name,
.item-img-wrapper .item-content,
.item-img {
  height: 124px !important;
  line-height: 124px !important;
  /* // border-bottom:none!important; */
}
.el-tag {
  margin-left: 10px;
}
.img {
  background: url("car-pic.png") center no-repeat;
}
.el-form-item {
  margin-right: 79px;
}
</style>
